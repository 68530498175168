import React, { useEffect } from "react";
import { useState } from "react";
import Logo from "./images/logo.png";
import Square1 from "./images/square1.png";
import Square2 from "./images/square2.png";
import Background from "./images/gift-background.jpg";
import Giftko from "./components/Giftko.jsx";
import "./App.css";

export default function App() {
  const [currentPage, setCurrentPage] = useState("Home");

  const handlePageChange = (page) => () => {
    setCurrentPage(page);
  };

  console.log("Rendered");


  return (
    <div>
      <div id="bloglo-topbar">
        <div className="bloglo-container">
          <div className="bloglo-flex-row">
            <div className="col-md flex-basis-auto start-sm">
              <div className="bloglo-topbar-widget__text bloglo-topbar-widget bloglo-all">
                <span><i className="bi bi-gift bloglo-icon"></i><strong>Perfect gifts for everyone!</strong></span>
              </div>
            </div>
            <div className="col-md flex-basis-auto end-sm">
              <div className="bloglo-topbar-widget__text bloglo-topbar-widget bloglo-all">
                <span><i className="bi bi-send bloglo-icon"></i> Subscribe to our newsletter &amp; never miss our newest recommendations. <a href="#"><strong>Subscribe Now!</strong></a></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="bloglo-header-inner" className="snipcss-4Iedo">
        <div className="bloglo-container bloglo-header-container">
          <div className="bloglo-logo bloglo-header-element" itemType="https://schema.org/Organization" itemScope="itemscope">
            <div className="logo-inner">
              <a href="https://blog.what.gifts/" rel="home" className="" itemProp="url">
                <img src={Logo} alt="Logo of what.gifts" width="1966" height="267" className="" itemProp="logo" />
              </a>
            </div>
          </div>
          <span className="bloglo-header-element">
            <nav className="site-navigation main-navigation bloglo-primary-nav bloglo-nav bloglo-header-element" role="navigation" itemType="https://schema.org/SiteNavigationElement" itemScope="itemscope" aria-label="Site Navigation" aria-haspopup="true">
              <ul id="bloglo-primary-nav" className="menu">
                <li id="menu-item-53" className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-53 ${currentPage === 'Home' ? 'current-menu-item current_page_item menu-item-home' : ''}`}><a href="#" onClick={handlePageChange("Home")} className={`${currentPage === 'Home' ? 'block-link' : ''}`}><span>Home</span></a></li>
                <li id="menu-item-29" className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-29 ${currentPage === 'GiftAI' ? 'current-menu-item current_page_item menu-item-home' : ''}`}><a href="#" onClick={handlePageChange("GiftAI")} className={`${currentPage === 'GiftAI' ? 'block-link' : ''}`}><span>Gift Assistant</span></a></li>
                <li id="menu-item-30" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-30"><a href="https://blog.what.gifts" aria-current="page"><span>Blog</span></a></li>
              </ul>
            </nav>
          </span>
          <div className="bloglo-header-widgets bloglo-header-element bloglo-widget-location-right">
            <div className="bloglo-header-widget__socials bloglo-header-widget bloglo-hide-mobile-tablet">
              <div className="bloglo-widget-wrapper">
                <nav className="bloglo-social-nav rounded bloglo-standard">
                  <ul id="menu-social" className="bloglo-socials-menu">
                    <li id="menu-item-39" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-39">
                      <a href="https://x.com/WhatGiftsX">
                        <span className="screen-reader-text">X</span>
                        <span className="twitter">
                          <svg className="bloglo-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                            <path d="M14.258 10.152 23.176 0h-2.113l-7.747 8.813L7.133 0H0l9.352 13.328L0 23.973h2.113l8.176-9.309 6.531 9.309h7.133zm-2.895 3.293-.949-1.328L2.875 1.56h3.246l6.086 8.523.945 1.328 7.91 11.078h-3.246zm0 0"></path>
                          </svg>
                        </span>
                      </a>
                    </li>
                    <li id="menu-item-27" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-27">
                      <a href="https://www.instagram.com/whatgiftsinsta/">
                        <span className="screen-reader-text">Instagram</span>
                        <span className="instagram">
                          <svg className="bloglo-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                            <path d="M16.016 3.284c4.317 0 4.755.063 6.444.125 1.627.063 2.44.313 3.003.5.751.313 1.314.688 1.814 1.189.563.563.938 1.126 1.189 1.814.25.626.5 1.439.563 3.003.063 1.752.125 2.19.125 6.506s-.063 4.755-.125 6.444c-.063 1.627-.313 2.44-.5 3.003-.313.751-.688 1.314-1.189 1.814-.563.563-1.126.938-1.814 1.189-.626.25-1.439.5-3.003.563-1.752.063-2.19.125-6.506.125s-4.755-.063-6.444-.125c-1.627-.063-2.44-.313-3.003-.5-.751-.313-1.314-.688-1.814-1.189-.563-.563-.938-1.126-1.189-1.814-.25-.626-.5-1.439-.563-3.003-.063-1.752-.125-2.19-.125-6.506s.063-4.755.125-6.444c.063-1.627.313-2.44.5-3.003.313-.751.688-1.314 1.189-1.814.563-.563 1.126-.938 1.814-1.189.626-.25 1.439-.5 3.003-.563 1.752-.063 2.19-.125 6.506-.125m0-2.877c-4.379 0-4.88.063-6.569.125-1.752.063-2.94.313-3.879.688-1.064.438-2.002 1.001-2.878 1.877S1.251 4.911.813 5.975C.438 6.976.187 8.102.125 9.854.062 11.543 0 12.044 0 16.423s.063 4.88.125 6.569c.063 1.752.313 2.94.688 3.879.438 1.064 1.001 2.002 1.877 2.878s1.814 1.439 2.878 1.877c1.001.375 2.127.626 3.879.688 1.689.063 2.19.125 6.569.125s4.88-.063 6.569-.125c1.752-.063 2.94-.313 3.879-.688 1.064-.438 2.002-1.001 2.878-1.877s1.439-1.814 1.877-2.878c.375-1.001.626-2.127.688-3.879.063-1.689.125-2.19.125-6.569s-.063-4.88-.125-6.569c-.063-1.752-.313-2.94-.688-3.879-.438-1.064-1.001-2.002-1.877-2.878s-1.814-1.439-2.878-1.877C25.463.845 24.337.594 22.585.532c-1.689-.063-2.19-.125-6.569-.125zm0 7.757c-4.567 0-8.258 3.691-8.258 8.258s3.691 8.258 8.258 8.258c4.567 0 8.258-3.691 8.258-8.258s-3.691-8.258-8.258-8.258zm0 13.639c-2.94 0-5.38-2.44-5.38-5.38s2.44-5.38 5.38-5.38 5.38 2.44 5.38 5.38-2.44 5.38-5.38 5.38zM26.463 7.851c0 1.064-.813 1.939-1.877 1.939s-1.939-.876-1.939-1.939c0-1.064.876-1.877 1.939-1.877s1.877.813 1.877 1.877z"></path>
                          </svg>
                        </span>
                      </a>
                    </li>
                    <li id="menu-item-28" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-28">
                      <a href="https://www.youtube.com/@WhatGifts"><span className="screen-reader-text">YouTube</span><span className="youtube">
                        <svg className="bloglo-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                          <path d="M31.718 10.041s-.313-2.252-1.251-3.191c-1.251-1.314-2.628-1.314-3.253-1.376-4.442-.313-11.198-.313-11.198-.313s-6.757 0-11.198.313c-.626.063-2.002.063-3.253 1.376-.938.938-1.251 3.191-1.251 3.191s-.313 2.565-.313 5.13v2.44c0 2.628.313 5.193.313 5.193s.313 2.19 1.251 3.191c1.251 1.251 2.878 1.251 3.566 1.376 2.565.25 10.886.313 10.886.313s6.757 0 11.198-.313c.626-.063 2.002-.125 3.253-1.376.938-1.001 1.251-3.191 1.251-3.191s.313-2.565.313-5.193v-2.44c0-2.565-.313-5.13-.313-5.13zm-11.386 6.632l-7.57 3.941v-9.009l8.633 4.504z"></path>
                        </svg>
                      </span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      {currentPage === "Home" ?
        <div className="content">
          <div className="container-fluid">
            <div className="row hero-background-image">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <h1 className="hero-header display-1 text-uppercase text-center">Find the perfect gift ideas!</h1>
              </div>
            </div>
            <div className="row">
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-sm-12 p-5 order-sm-1">
                <img src={Square1} />
              </div>
              <div className="col-xl-6 col-sm-12 d-flex align-items-center order-sm-2">
                <div className="">
                  <h2 className="display-3 text-uppercase">The Ultimate Destination for Your Gifts</h2>
                  <p>At What.Gifts, we believe that every gift tells a story. Our platform is designed to help you create meaningful connections through thoughtful and personalized presents. Whether you’re looking for unique ideas for a special occasion, creative ways to package your gifts, or expert advice on selecting the perfect present, we’ve got you covered. From handmade treasures to trendy recommendations, our curated content ensures that every gift-giving moment is unforgettable.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-sm-12 d-flex align-items-center order-sm-4 order-xl-3">
                <div className="">
                  <h2 className="display-3 text-uppercase">Explore, Create, Share</h2>
                  <p>Dive into our world of gift-making tutorials, reviews, and guides to spark your creativity and simplify your search. Our extensive blog features everything from DIY crafts to in-depth reviews of the latest products, helping you find gifts that stand out. Plus, with tips on presentation and heartfelt messages, you’ll make your gifts more than just an exchange—they’ll become cherished memories. Let What.Gifts be your trusted companion in making every gift as special as the person receiving it.</p>
                </div>
              </div>
              <div className="col-xl-6 col-sm-12 p-5 order-sm-3 order-xl-4">
                <img src={Square2} />
              </div>
            </div>
          </div>
        </div>
        :
        <div className="content">
          <div className="container-fluid">
            <div className="row hero-background-image">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <h1 className="hero-header display-1 text-uppercase text-center">Coming soon!</h1>
              </div>
            </div>
          </div>
          <div>
          </div>
        </div>
      }

      <div class="container-fluid footer">
        <footer class="py-3 my-4">
          <ul class="nav justify-content-center border-bottom pb-3 mb-3">
            <li class="nav-item"><a href="#" class="nav-link px-2 text-body-secondary">Home</a></li>
            <li class="nav-item"><a href="#" class="nav-link px-2 text-body-secondary">Blog</a></li>
          </ul>
          <p class="text-center text-body-secondary">© 2024 what.gifts. All rights reserved | contact@what.gifts</p>
        </footer>
      </div>

    </div>

  )
}